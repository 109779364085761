@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

$headersFont: "Roboto";
$textFont: "Roboto";

$primary-color: #e87b22;
$header-bg-color: black;
$blue-color: #0d77ce;
$light-blue-color: #0d77ce;
$green-color: #09b437;
$light-green-color: #09b437;
$orange-color: #f5af17;
$light-orange-color: #f5af17;
$red-color: #cf3d3d;
$light-red-color: #cf3d3d;
$grey-color: #666;
$placeholder-color: #747474;
$wrapper-bg-color: #eceff1;

$navbarLogoWidthSm: 70px;
$navbarLogoWidthMd: 105px;
$navbarLogoWidthLg: 120px;

$miniNavbarLogoWidthSm: 105px;
$miniNavbarLogoWidthMd: 120px;
$miniNavbarLogoWidthLg: 120px;

$navbarDrawerBreakpoint: 900px;

// wrapper min height
// frontpage
$wrapperFWMinheightXs: 130px;
$wrapperFWMinheightSm: 130px;
$wrapperFWMinheightMd: 190px;
$wrapperFWMinheightLg: 190px;
$wrapperFWMinheightXl: 245px;
// static page
$wrapperMinheightXs: 110px;
$wrapperMinheightSm: 110px;
$wrapperMinheightMd: 173px;
$wrapperMinheightLg: 173px;
$wrapperMinheightXl: 222px;

$slideBg: black;
